import * as React from 'react';
import { graphql } from 'gatsby';
import Layout from '../layouts';
import * as showdown from 'showdown';
import { Box } from '@material-ui/core';
import SEO from '../components/seo';
const converter = new showdown.Converter();

export const BasicPageTemplate = ({
  title,
  displayTitle,
  excerpt,
  content,
  date,
}: {
  title: string;
  displayTitle: string;
  excerpt: string;
  content: any;
  date: Date;
}) => {
  const formatDate = new Intl.DateTimeFormat('en-US', {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  }).format(new Date(date));

  return (
    <div className="row">
      <SEO title={title} description={excerpt} />
      <div className="contain-row">
        <div className="col-xs-12 my-u5">
          <Box justifyContent="space-between" display="flex">
            <h1 className="font-u1 mb-b mr-u2">{displayTitle}</h1>
            <h2>{formatDate}</h2>
          </Box>
          <div
            className="markdown-holder"
            dangerouslySetInnerHTML={{ __html: converter.makeHtml(content) }}
          />
        </div>
      </div>
    </div>
  );
};

const BasicPage = ({
  data: {
    markdownRemark: {
      frontmatter: {
        title = '',
        displayTitle = '',
        content = '',
        category = 'all',
        date = new Date(),
        excerpt = '',
      },
    },
  },
}) => {
  return (
    <Layout>
      <BasicPageTemplate
        title={title}
        displayTitle={displayTitle}
        content={content}
        date={date}
        category={category}
        excerpt={excerpt}
      />
    </Layout>
  );
};

export default BasicPage;

export const basicPageQuery = graphql`
  query NewsPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      htmlAst
      frontmatter {
        title
        displayTitle
        content
        date
        category
        excerpt
      }
    }
  }
`;
